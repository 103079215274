import React from 'react'
import styled from 'styled-components'
import CustomText from '../custom_components/CustomText'
import { Link } from 'react-router-dom'
 import { logOut } from '../../firebase/utils'
 import { aboutUsRef, decorRef, storesRef, } from '../../screens/HomeScreen'
// import { auth, logOut } from '../../firebase/utils'
// import { CustomSection, SimpleLink } from '../custom_components/SharedComponents'
// import { signOut } from 'firebase/auth'

const MenuContainer = styled.nav`
    display: flex;
    width:100%;
    height:64px;
    background-color: ${props => props.theme.colors.white};
    justify-content:space-between;
    align-items:center;
    //padding:0 48px;

    @media (max-width:768px)
    {
        display:none;
    }

    a {
        text-decoration: none;
        color:${props=>props.theme.colors.primaryMain};
        p {
        &:hover
        {
            text-decoration: underline;
        }}
        h1 {
        transition: 0.22s ease-in-out;
        &:hover{
            transform:scale(105%);

        }
        }
    }
`

const MenuItem = styled.p`
    font-size:18px;
    text-decoration: none;
    text-align: center;
    text-transform: uppercase;
    color: ${props => props.theme.colors.primaryMain};
    padding: 16px 24px;
    min-width:130px;
    margin:0;
    transition: font-weight ease-in-out 0.85;
    &:hover{
        font-weight:bold;
    }
    &:active{
        text-decoration: underline;
    }
`
export const Logo = styled.h1`
    text-decoration: none;
    font-size:26px;
    text-transform: uppercase;
    font-weight:bold;
    text-align: center;
    min-width: 200px;
    color:${props => props.theme.colors.primaryMain};
    margin:0;

`

const Fitter = styled.div`
    //width:1000px;
    padding:0px 48px;
    display:flex;
    flex-direction: row;
    align-items:left;
    justify-content:${props => props.justifyContent || "space-between"};
`
const MenuOptions = styled.div`
    display:flex;
    flex-direction:row;
`



export default function Navigation(props) {
    const { currentUser,storesRef,aboutUsRef,decorRef } = props


    async function handleLogout(){
        try {
            await logOut();
        } catch (error) {
            alert(error)
        }
    }

    const scrollDown = (ref) => {
        window.scrollTo({
          top: ref.current.offsetTop,
          behavior: 'smooth',
        });
      };

    return (
        <>
        <MenuContainer>

            <Fitter>
                <Link to="/"><Logo>Park és Kert</Logo></Link>
            </Fitter>

            <MenuOptions>
                    <MenuItem onClick={() => scrollDown(aboutUsRef)} >Történetünk</MenuItem>
                    <MenuItem onClick={() => scrollDown(decorRef)}  >Dekoráció</MenuItem>
                    {/* <MenuItem>Kertépítés</MenuItem> */}
                    <MenuItem onClick={() => scrollDown(storesRef)}>Kapcsolat</MenuItem>
            </MenuOptions>
            <Fitter><Link to="/"><Logo color="white"></Logo></Link></Fitter>
        </MenuContainer>
        {/* <InfoLine>
            <Fitter justifyContent="flex-end">
               { currentUser == null  && <SimpleLink to="/bejelentkezes"><CustomText padding="0 10px">BEJENETKEZÉS</CustomText> </SimpleLink> }
               { currentUser !== null  && <CustomText padding="0 10px">{currentUser?.displayName || currentUser?.email}</CustomText>}
               { currentUser !== null && <button onClick={() => handleLogout()}>Kijelentkezés</button>}

            </Fitter>
        </InfoLine>  */}
        </>

    )
}

