import React from 'react'
import styled from 'styled-components'
import { Logo } from './Navigation/Navigation'
import  Instagram  from '../assets/svgs/icons/instagram_icon.svg'
import  Facebook  from '../assets/svgs/icons/facebook.svg'
import LogoImg from '../assets/imgs/LogoGradiant.svg'
import CustomText from './custom_components/CustomText'

const Section = styled.section`
    width:100%;
    height:auto;
    background-color:black;
    align-items: center;
`

const Container = styled.div`
    display:flex;
    flex-direction: ${props => props.direction || 'column'};
    align-items:center;
    justify-content: space-evenly;
    padding:50px 0;
    
`

const Img = styled.img`
    width: ${props => props.size || '150px'};
    height:${props => props.size || '150px'};
`

export default function Footer() {
    return (
        <Section>
            <Container>
                <Img src={LogoImg}/>
                <Logo>Park és Kert</Logo>
                <CustomText><a href="mailto:info@parkeskert.com">info@parkeskert.com</a></CustomText>
                <CustomText margin="10px 0 0 0">Tel.: +36 20 288 82 88</CustomText>
                <CustomText>Magyarország, 6200 - Kiskőrös</CustomText>
                <CustomText>Kiskőrös Petőfi Sándor utca 29.-31.</CustomText>

            </Container>
        </Section>
    )
}
