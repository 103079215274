import React from 'react'
import styled from 'styled-components'
import MobileNav from './MobileNav'
import Navigation from './Navigation'

const Root = styled.div`
    position:fixed;
    top:0;
    left:0;
    width:100%;
    z-index:100;
`

export default function TopBar(props) {

    const { currentUser, aboutUsRef, storesRef,decorRef } = props

    return (
        <Root>
            <Navigation currentUser={currentUser} aboutUsRef={aboutUsRef} decorRef={decorRef} storesRef={storesRef}/>
            <MobileNav/>
        </Root>
    )
}
