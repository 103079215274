import React, {useState} from 'react'
 import styled from 'styled-components'
import MobileNavMenu from './MobileNavMenu'

const Root = styled.div`
    flex-direction:column;
    height:100%;
    display:none;
    @media (max-width: 768px)
    {
        display:flex;
    }
`

const Container = styled.nav`
    display:flex;
    align-items:center;
    justify-content: center;
    height:48px;
    background-color:${props => props.theme.colors.backgroundActive};

`
const Logo = styled.h1`
    text-decoration: none;
    font-size:20px;
    text-transform: uppercase;
    font-weight:bold;
    color:${props => props.theme.colors.primaryMain};
    padding:0;
    margin:0;
`
const StyledBurger = styled.div`
  width: 22px;
  height: 22px;
  position: absolute;
  top: 14px;
  left: 13px;
  z-index: 20;
  display: none;
  @media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;
  }
  div {
    width: 22px;
    height: 1.5px;
    background-color: ${props => props.theme.colors.primaryMain};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.185s ease;
    &:nth-child(1) {
      transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
      transform: ${({ open }) => open ? 'scale(0)' : 'scale(1)'};
      opacity: ${({ open }) => open ? 0 : 1};
    }
    &:nth-child(3) {
      transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
  }
`;

export default function MobileNav() {
    const [open, setOpen] = useState(false)

    return (
        <Root>
            <StyledBurger open={open} setOpen={(value) => setOpen(value)} onClick={() => setOpen(!open)}>
            <div />
            <div />
            <div />
            </StyledBurger>
            <Container>
                <Logo>Park és kert</Logo>
            </Container>
            <MobileNavMenu open={open}/>

        </Root>
    )
}
