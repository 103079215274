import React from 'react'
import styled from 'styled-components'
import StoreCard from '../components/StoreCard'
import Store2 from '../assets/imgs/s5.jpg'
import Store1 from '../assets/imgs/store1.jpg'
import Store3 from '../assets/imgs/store3.jpg'
import BlackLogo from '../assets/imgs/black-logo.svg'
import CustomText from '../components/custom_components/CustomText'
import { NormalWrapper,BackgroundSvg, CustomSection, GridWrapper, SectionTitle } from '../components/custom_components/SharedComponents'
import GalleryImage from '../components/custom_components/GalleryImage'

import Flower1 from '../assets/imgs/csokrok/flower1.png'
import Flower2 from '../assets/imgs/csokrok/flower2.png'
import Flower3 from '../assets/imgs/csokrok/flower3.png'
import Flower4 from '../assets/imgs/csokrok/flower4.png'
import Flower5 from '../assets/imgs/csokrok/flower5.png'
import Flower6 from '../assets/imgs/csokrok/flower6.png'
import Koszoru1 from '../assets/imgs/koszorúk/koszoru4.png'
import Koszoru2 from '../assets/imgs/koszorúk/koszoru2.png'
import Koszoru3 from '../assets/imgs/koszorúk/koszoru3.png'



const Root = styled.div`
    width:100%;
    height:auto;
`


export const BackgroundSvg2 = styled.img`
    width:500px;
    height:500px;
    position: fixed;
    right:-160px;
    bottom:-65px;
    overflow:hidden;
    opacity:20%;

    @media (min-width: 768px)
    {
        width:800px;
        height:800px;
        right:-180px;
        bottom:-180px;
    }
`

export default function StoresScreen(props) {
    const {storesRef} = props
    return (
        <Root>
            <CustomSection padding="45px 0 96px 0" backgroundColor="primarySub" ref={storesRef}>
                <SectionTitle color="primaryMain">Üzleteink</SectionTitle>
                <NormalWrapper>
                <CustomText>
                    Sok szeretettel várjuk kedves ügyfeleinket három különböző helyszínen Kiskőrös városában. 
                    Rendelés leadása lehetséges telefonon vagy személyesen, bármely üzletünkben .
                </CustomText>
                </NormalWrapper>
                <GridWrapper margin = "25px auto 0 ">
                    <StoreCard 
                        storeName="Otthonunk és kertünk"
                        storeType="Virágüzlet"
                        address={"6200 Kiskőrös,\t Petőfi Sándor út 29"}
                        addressHref="https://goo.gl/maps/9MdS7pd2MuVUUBZj8"
                        email="info@parkeskert.com"
                        telefon="+36 20 288 82 88"
                        openingHours={["Hétfő - Péntek: 08:00-18:00","Szombat: 09:00-16:00","Vasárnap: 09:00-12:00"]}
                        img={Store1}
                    />
                    <StoreCard                        
                        storeName="Otthonunk és kertünk"
                        storeType="Kertészet"
                        address={"6200 Kiskőrös,\t Petőfi Sándor út 31"}
                        addressHref="https://goo.gl/maps/9MdS7pd2MuVUUBZj8"
                        email="info@parkeskert.com"
                        telefon="+36 20 288 82 88"
                        openingHours={["Hétfő - Péntek: 08:00-18:00","Szombat: 09:00-16:00","Vasárnap: 09:00-12:00"]}
                        img={Store2}
                        />
                    <StoreCard                        
                        storeName="Piactéri Virágüzlet"
                        storeType="Virágüzlet"
                        address={"6200 Kiskőrös,\t Piac tér 1"}
                        addressHref="https://goo.gl/maps/9MdS7pd2MuVUUBZj8"
                        email="info@parkeskert.com"
                        telefon="+36 78 412 210"
                        openingHours={["Hétfő - Kedd: 09:00-17:00", "Szerda: 8:00-17:00", "Csütörtök- Péntek: 09:00-17:00","Szombat: 09:00-13:00","Vasárnap: 09:00-12:00"]}
                        img={Store3}
                    />
                </GridWrapper>
            </CustomSection>
            <CustomSection>
            <BackgroundSvg src={BlackLogo}/>
                <SectionTitle>Virágok és csokrok</SectionTitle>
                <GridWrapper gridGap="auto">
                    <GalleryImage src={Flower1} title="Ajándék dobozok"/>
                    <GalleryImage src={Flower2} title="Cserepes virágok"/>
                    <GalleryImage src={Flower3} title="Ajándék dobozok"/>
                    <GalleryImage src={Flower4} title="Virágcsokor"/>
                    <GalleryImage src={Flower5} title="Ajándék dobozok"/>
                    <GalleryImage src={Flower6} title="Virágos láda"/>
                </GridWrapper>
            </CustomSection>
            <CustomSection backgroundSize="cover">
                <SectionTitle>Koszorúk</SectionTitle>
                <BackgroundSvg src={BlackLogo}/>
                <GridWrapper gridGap="auto">
                    <GalleryImage src={Koszoru1} title="Szív alakú koszorú"/>
                    <GalleryImage src={Koszoru3} title="Koszorú"/>

                    <GalleryImage src={Koszoru2} title="Koszorú"/>
 
                </GridWrapper>
            </CustomSection>
        </Root>
    )
}
