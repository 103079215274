import styled from "styled-components"
import { ThemeProvider } from 'styled-components';
import './App.css';
import ScreenController from './components/ScreenController';
import theme from "./styles/theme"
import { useAuth } from "./firebase/utils";
import { ToastContainer } from "react-toastify";

const Root = styled.div`
  font-family: 'Buenard', serif;
  height:100vh;
  button{
    font-family: 'Buenard', serif;

  }
`


function App() {
  
  const currentUser = useAuth();




  return (
    <>
    <ToastContainer/>
    <Root>
    <ThemeProvider theme={theme}>
        <ScreenController currentUser={currentUser}/>
    </ThemeProvider>
    </Root>
    </>
  );
}

export default App;
