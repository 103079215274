import React from 'react'
import { CustomSection } from '../components/custom_components/SharedComponents'
import CustomText from '../components/custom_components/CustomText'
export default function NotFoundScreen() {
    return (
        <div>
            <CustomSection>
                <CustomText>404 Hiba, ez az oldal nem létezik.</CustomText>
            </CustomSection>

        </div>
    )
}
