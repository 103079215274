import React from 'react'
import styled from 'styled-components'

const FancyText = styled.p`
    font-size: ${props => props.fontSize +"px"|| "14px"};
    margin: ${props => props.margin || 0};
    font-weight: ${props => props.bold ? 'bold': 'normal' };
    color:  ${props => props.color ? props.theme.colors[props.color] || props.color : props.theme.colors.white};
    text-shadow: ${props => props.shadow ?  '2px 2px 3px #000000'  : 'none'};
    text-align:${props => props.textAlign || "center"};
    opacity:${props => props.opacity};
    padding: ${props => props.padding || "0px"};

    a {
        text-decoration: none;
        color:${props => props.color ? props.theme.colors[props.color] || props.color : props.theme.colors.primaryMain};

        &:hover{
            color:${props => props.color ? props.theme.colors[props.color] || props.color : props.theme.colors.primaryMainActive};
            text-decoration: underline;
        }
    }

    @media (max-width: 768px)
    {
        text-align:${props=> props.mobileAlign}
    }
` 

export default function CustomText( props ) {
    const { children } = props
    return (
        <FancyText
        {...props}
        >{children}</FancyText>
    )
}
