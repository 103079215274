import React from 'react'
import styled from 'styled-components'
import { BackgroundSvg, CustomSection, SectionTitle } from '../components/custom_components/SharedComponents'
import SignIn from '../components/SignIn/SignIn'
import BlackLogo from '../assets/imgs/black-logo.svg'


function LoginScreen() {
    return (
        <div>
            <BackgroundSvg src={BlackLogo}/>
            <CustomSection padding="150px 0 25px">
                <SignIn></SignIn>
            </CustomSection>
        </div>
    )
}

export default LoginScreen
