// import { stringify } from '@firebase/util';
import React, { useRef, useState } from 'react'
//import  { Component} from 'react'

import { googleSignIn, passwordLogin, useAuth } from '../../firebase/utils'
import styled from 'styled-components';
import { CustomInput, Line, SimpleLink } from '../custom_components/SharedComponents';
//import { CustomInput } from '../custom_components/SharedComponents';
// import { Link } from 'react-router-dom';
import { localiseErrors } from '../../firebase/firebaseErrors';
import CustomText from '../custom_components/CustomText';

export const Card = styled.div`

    width:400px;
    height:auto;
    min-height:250px;
    background-color:white;
    box-shadow:  5px 5px 10px ${props => props.theme.colors.backgroundTransparent};
    
    @media (max-width: 456px)
    {
        width:285px;
    }
`

export const InputWrapper = styled.div`
    display:flex;
    flex-direction: column;
    width:90%;
    margin:1.5% auto;
    label{
        font-size:14px;
        text-align:left;
    }
`

export const Wrapper = styled.div`
    display:flex;
    flex-direction: column;
    width:100%;
`
export const Spacer = styled.div`
    padding: 0 0 16px 0;
    display:flex;
    flex-direction: column;
    width:100%;
`

export const LoginInput = styled.input`
    width: "90%";
    background:${props => props.theme.colors.white};
    border: 5px 5px;
    padding: 8px 16px;
    
    border-style: solid;
    border-color:${props => "lightgray"};

    color:black;
    letter-spacing: 0.75px;
    font-size: 16px;
    
    &:active{
        border-color:${props => props.theme.colors.primaryMainActive};
    }
    
`

export const LoginButton = styled.button`
    width:100%;
      
      text-transform: uppercase;
      padding: 12px 48px;
      transition: 0.22s ease-in-out;
      margin: ${props=> props.margin || "0 0"};
      font-size: 16px;
      letter-spacing: 1.5px;
      border-style:none;
      background-color: ${props => props.backgroundColor ? props.theme.colors[props.backgroundColor] || props.backgroundColor : props.theme.colors.white};
      color: ${props => props.color ? props.theme.colors[props.color] || props.color : props.theme.colors.black};
      &:hover
      {
          font-weight:bold;
          color:${props => props.colorHover ? props.theme.colors[props.colorHover] || props.colorHover : props.theme.colors.black};
          background-color:${props => props.backgroundColorHover ? props.theme.colors[props.backgroundColorHover] || props.backgroundColorHover : props.theme.colors.backgroundActive};
      }
`

export default function SignIn() {
    const emailRef = useRef()
    const passwordRef = useRef()
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState([]);

    async function handleLogin(){
        setIsLoading(true)
           setErrors([])
           await passwordLogin(emailRef.current.value, passwordRef.current.value)
           .catch((err) => {
                setErrors(oldArray => [...oldArray, localiseErrors(err)]);
           })
        setIsLoading(false)
    }
    async function handleGoogleLogin(){
        try {
            googleSignIn()
        } catch (error) {
            alert(error)
        }
    }


    return (
        <Card>
            <Wrapper>
                <h2>BEJELENTKEZÉS</h2>
                <Line margin="0 auto 16px"/>
                <Spacer>
                    <InputWrapper>
                    <label>Email cím</label>
                    <LoginInput  ref={emailRef} type="email"placeholder="pl. pelda@gmail.com"/>
                    </InputWrapper>

                    <InputWrapper>
                    <label>Jelszó</label>
                    <LoginInput ref={passwordRef} type="password" placeholder="Jelszó"/>
                    </InputWrapper>

                    { errors.length > 0 && (
                    <>{
                    errors.map((err) => {
                        return(
                            <CustomText color="red">{err}</CustomText>
                    )})}
                   </>) }
                </Spacer>
                <LoginButton backgroundColorHover="primaryMain" disabled={isLoading} onClick={() => handleLogin()} >Bejelentkezés</LoginButton>
                <LoginButton backgroundColorHover="googleRed" colorHover="white" onClick={handleGoogleLogin}>Google bejelentkezés</LoginButton>
                <SimpleLink to="/regisztracio"><LoginButton backgroundColorHover="primaryMain">Regisztráció</LoginButton> </SimpleLink>
            </Wrapper>
        </Card>
    )
}




