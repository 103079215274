
export const firebaseConfig = {
  apiKey: "AIzaSyC1ypf_HzFNpBWeTsHVQn44dKqv1yU1XtI",
  authDomain: "parkeskert-dev.firebaseapp.com",
  projectId: "parkeskert-dev",
  storageBucket: "parkeskert-dev.appspot.com",
  messagingSenderId: "655753749190",
  appId: "1:655753749190:web:d3a6a6fa4d586518262dc7",
  measurementId: "G-6JEY711YLF"
};
