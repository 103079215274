import React from 'react'
import styled from 'styled-components'
import OpenStore from '../assets/imgs/OpenStorePic.png'
import CustomText from '../components/custom_components/CustomText'
import BaIrén from '../assets/imgs/BaIrén_cropped.jpg'
import BlackLogo from '../assets/imgs/black-logo.svg'
import {CustomSection, CustomGridWrapper, SectionTitle, BackgroundSvg, HalfGridWrapper} from '../components/custom_components/SharedComponents'
import FlexImage from '../components/FlexImage'
import DecorationScreen from './DecorationScreen'
import StoresScreen from './StoresScreen'

const WelcomeScreen = styled.section`
    display:flex;
    width:100%;
    height:100%;
    background-color: ${props => props.theme.colors.background};
    background-image: url(${OpenStore});
    background-repeat: no-repeat;
    background-size: cover;
    align-items:center;
`
const WelcomeText = styled.h1`
    margin: auto;
    color: white;
    max-width:500px;
    @media (max-width: 768px)
    {
        font-size: ${props => props.theme.fontSize.md};
        max-width:350px;
    }
`

const FlexWrapper = styled.div`
    display:flex;
    align-content: center;
    align-items:center;
    flex-direction: ${props => props.directionMobile || 'column'};
    justify-content: ${props => props.justifyContentMobile || "space-evenly"};
    margin: ${props => props.margin || "48px 8px"};
    padding: ${props => props.padding || "0px"};
    
    @media (min-width: 768px)
    {        
        flex-direction: ${props => props.direction || 'column'};
        justify-content: ${props => props.justifyContent || "space-evenly"};
    }  
`



const SignUpButton = styled(CustomText)`
    display:block;
    font-size: 14px;
    letter-spacing: 1px;
    align-content: center;
    justify-content:center;
    text-align:center;
    min-width:125px;
    width:auto;
    padding:10px 24px;
    height:auto;
    margin:0px;
    background-color: ${props => props.theme.colors.primarySub};
    border-style:solid;
    border: 0px;
    margin: 25px 0;
    max-width:400px;
    &:hover{
        background-color: ${props => props.theme.colors.primarySubActive};
    }

    @media (min-width: 768px)
    {
        margin: 15px 0 0 0px;
    }
`





export default function HomeScreen(props) {
    const { aboutUsRef, storesRef, decorRef} = props
    return (
            <>
            <WelcomeScreen>
                <WelcomeText>Díjnyertes virágkötészet, professzionális rendezvény dekoráció és kertészeti munkák. </WelcomeText>
            </WelcomeScreen>

            <BackgroundSvg src={BlackLogo}  />

            <CustomSection ref={aboutUsRef} backgroundColor="primarySub" padding="0" >
                    <CustomGridWrapper template="1fr 1fr">
                        <HalfGridWrapper>
                            <FlexWrapper >
                                <SectionTitle marginTop="0" color="primaryMain">Történetünk</SectionTitle>
                                <CustomText textAlign="justify" mobileAlign="left">
                                        Családunk több évtizedes tapasztalattal rendelkezik a szakmában. Szüleim éveken át foglalkoztak virágtermesztéssel, később pedig saját virágüzletet nyitottak. Engem már gyermekoromban magukkal ragadtak a virágok és a növények. Sokat segítettem a szüleienk a fóliákban és a piacon. Később, kertészetet tanultam majd kertészmérnökként diplomáztam a Szent István Egyetemen. Munkám a hivatásom és minden nap azért dolgozok, hogy a legszebb és legjobb minőségű virágokkal és növényekkel várhassuk vásárlóinkat immár 3 üzletünkben Kiskőrösön és környékén.
                                </CustomText>
                            </FlexWrapper>
                        </HalfGridWrapper>
                        <FlexImage image={BaIrén} title="Ba Irén" desc="Kertészmérnök, Tulajdonos" />
                    </CustomGridWrapper>
            </CustomSection>

            <DecorationScreen decorRef={decorRef}/>
            <StoresScreen storesRef={storesRef}/>
            </>
    )
}
