import React from 'react'
import styled from 'styled-components'
import CustomText from './custom_components/CustomText'
import { FlexBox } from './custom_components/SharedComponents'

const Image = styled.div`
    background-image:  url(${props => props.image});
    width:100%;
    height:91vh;
    background-color: red ;
    background-size: cover;
    background-position: center;
    position:relative;

    >* {
      background-color: rgba(0,0,0,55%);
      position: absolute;
      bottom: 0;
      left: 0;
      width:100%;
    }
`

const TextWrapper = styled.div`
  padding: 24px 16px;
  text-align:left;
`


function FlexImage(props) {
  const {title,desc,image} = props
  return (
    <Image image={image}>
      <div>
        <TextWrapper>
          <CustomText shadow bold fontSize='24' textAlign="left" mobileAlign="center">{title}</CustomText>
          <CustomText shadow textAlign="left" mobileAlign="center">{desc}</CustomText>
        </TextWrapper>
      </div>
    </Image>
  )
}

export default FlexImage