import React from 'react'
//import { CustomInput, CustomSection, Line } from '../custom_components/SharedComponents'
import { Line } from '../custom_components/SharedComponents'

import { useRef } from 'react'
import { PasswordSignUp } from '../../firebase/utils'
import { useState } from 'react'
import CustomText from '../custom_components/CustomText'
//import { toast } from 'react-toastify'
import { localiseErrors } from '../../firebase/firebaseErrors'
import { Card, InputWrapper, LoginButton, LoginInput, Spacer, Wrapper } from './SignIn'




export default function SignUp() {

    const [errors, setErrors] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const emailRef = useRef("")
    const passwordRef = useRef("")
    const confirmPasswordRef = useRef("")
    const displayNameRef = useRef("")


    async function handleSignUp(){
        setErrors([])
        if( await checkData(passwordRef.current.value, confirmPasswordRef.current.value, emailRef.current.value, displayNameRef.current.value) )
        {
            setIsLoading(true)
            try
            {
                await PasswordSignUp(emailRef.current.value,passwordRef.current.value, {displayName:displayNameRef.current.value});
            }
            
            catch(err)
            {
                setErrors(oldArray => [...oldArray, localiseErrors(err)]);
            }
            
            setIsLoading(false);
        }
    }

    async function checkData(password, confirmPassword, email, displayName){

        var passed = true;

        if(displayName === ''){
            setErrors(oldArray => [...oldArray, 'A név nem lehet üres!']);
            passed=false;
        }
        else {

            if(displayName.length < 4 ){
                setErrors(oldArray => [...oldArray, 'A név túl rövid! (Min. 5 karakter)']);
                passed = false;
            }
        }

        
        if(email === ''){
            setErrors(oldArray => [...oldArray, 'Az email nem lehet üres!']);
            passed=false;
        }  
        else if(email.length < 5 || email === '' || email.includes(' ') || !email.includes('@') || !email.includes('.') ){
            setErrors(oldArray => [...oldArray, 'Helytel email cím!']);
            passed = false;
        }

        if(password === '' || confirmPassword === '')
        {
            setErrors(oldArray => [...oldArray, 'A jelszó nem lehet üres!']); 
            passed=false;
        }

        else if(password.length < 5 || password === '')
        {
            setErrors(oldArray => [...oldArray, 'A jelszó túl rövid! (Min. 6 karakter)!']);
            passed = false;
            
            if( password !== confirmPassword)
            {
                setErrors(oldArray => [...oldArray, 'A jelszavak nem egyeznek!']);
                passed = false;
            }
        }

        return(passed);
    }

    // function removeWhiteSpace(string)
    // {
    //     return string.replace(/\s+/g, '');
        
    // }

    return (
        <Card>
            <h2>Regisztráció</h2>
            <Line margin="0 auto 16px"/>
            <Wrapper>
                <Spacer>
                    {errors.length > 0 && (
                    <>{
                    errors.map((err,index) => {
                        return(
                            <CustomText color="red">{err}</CustomText>
                    )})}
                    </>) }
                    <InputWrapper>
                    <label>Ön neve</label>
                    <LoginInput  ref={displayNameRef} placeholder='Név pl: Kis Tamás'/>
                    </InputWrapper>
                    <InputWrapper>
                    <label>Email cím</label>
                    <LoginInput ref={emailRef} type="email"placeholder="Email cím"/>
                    </InputWrapper>
                    <InputWrapper>
                    <label>Jelszó</label>
                    <LoginInput  ref={passwordRef} type="password" placeholder="Jelszó"/>
                    </InputWrapper>
                    <InputWrapper>
                    <label>Jelszó megerősítés</label>
                    <LoginInput ref={confirmPasswordRef} type="password" placeholder="Jelszó ismét"/>
                    </InputWrapper>
                    </Spacer>
                    <LoginButton backgroundColorHover="primaryMain" disabled={isLoading} onClick={handleSignUp}>Regisztráció</LoginButton>
            </Wrapper>
        </Card>
    )


}
