import React from 'react'
import styled from 'styled-components'
//import Image from '../../assets/imgs/s1.jpg'
import CustomText from './CustomText'

const StyledImage = styled.img`
  height: 200px;
  width: 300px;
  transform: scale(1.0);
  transition: transform 0.4s ease;
  object-fit: cover;
  @media (max-width: 456px){
    height: 190px;
    width:275px;
    }
`

const ImageBox = styled.div`
    box-sizing: content-box;
    height: 200px;
    width: 300px;
    overflow: hidden;
    display: inline-block;
    color: white;
    position: relative;
    background-color: white;
    &:hover ${StyledImage}{
        transform: scale(1.1);
    }
    @media (max-width: 456px){
    height: 190px;
    width:275px;
    }
    
`
const Caption = styled.div`
  position: absolute;
  bottom: 0px;
  left: 20px;
  opacity: 0.0;
  transition: transform 0.3s ease, opacity 0.3s ease;

`
const TransparentBox = styled.div`
  height: 200px;
  width: 300px;
  background-color:rgba(0, 0, 0, 0);
  position: absolute;
  top: 0;
  left: 0;
  transition: background-color 0.3s ease;
  &:hover{
    background-color:rgba(0, 0, 0, 0.5);
  }
  &:hover ${Caption}{
    transform: translateY(-20px);
    opacity: 1.0;
  }
  @media (max-width: 456px){
    height: 190px;
    width:275px;
    }
`



export default function GalleryImage(props) {
    const {src, alt, title, subTitle} = props
    return (
        <div>
            <ImageBox>
                <StyledImage src={src} alt={alt}/>
                <TransparentBox>
                    <Caption>
                        <CustomText textAlign="left" bold>{title}</CustomText>
                        {subTitle !== undefined && <CustomText opacity="75%" textAlign="left">{subTitle}</CustomText>} 
                    </Caption>
                </TransparentBox>
            </ImageBox>
        </div>
    )
}
