import {firebaseConfig} from "./config"
import { createUserWithEmailAndPassword, getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithEmailAndPassword, signInWithPopup, signOut } from "firebase/auth"
import { initializeApp } from "@firebase/app"
import { useEffect, useState } from "react";
import { getFirestore, setDoc, doc, Timestamp, getDoc,  } from "firebase/firestore";
//import { collection, addDoc,onSnapshot   } from "firebase/firestore";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';


const app = initializeApp(firebaseConfig)
export const auth = getAuth();
export const firestore =  getFirestore();
const provider = new GoogleAuthProvider();


//Handlers
export async function PasswordSignUp(email,password, additionalData){
  return createUserWithEmailAndPassword(auth, email, password)
  .then((UserCredential)=>handleUserProfile(UserCredential.user, additionalData))
  .then(() => {
    toast.success("Sikeres regisztráció!")
    // ...
  })
}

export async function passwordLogin(email,password){
    return signInWithEmailAndPassword(auth,email,password)
    .then(() => {
      toast.success("Sikeresen bejelentkeztél!")})
    

  }

export function logOut(){

  return signOut(auth).then(() => {toast.success("Sikeresen kijelentkeztél!")} )

}




export const googleSignIn = () => signInWithPopup(auth, provider)
  .then((result) => {
    // This gives you a Google Access Token. You can use it to access the Google API.
    const credential = GoogleAuthProvider.credentialFromResult(result);
    const token = credential.accessToken;
    // The signed-in user info.
    const user = result.user;
    toast.success("Sikeresen bejelentkeztél!")
    // ...
  }).catch((error) => {
    // Handle Errors here.
    const errorCode = error.code;
    const errorMessage = error.message;
    // The email of the user's account used.
    const email = error.email;
    // The AuthCredential type that was used.
    const credential = GoogleAuthProvider.credentialFromError(error);
    // ...
  });


  //Custom hook
export function useAuth(){
  const [currentAuth, setCurrentAuth] = useState()

  useEffect(() => {
    const unsub = onAuthStateChanged(auth, (user)=> 
    {
      setCurrentAuth(user)
    })
    return unsub;
  }, [])
  return currentAuth;
}



  export const handleUserProfile = async (userAuth, additionalData) => 
  {
  
    if(!userAuth) return;
    //Check if user exists in the db
    const {uid} = userAuth;

    //If doesn't create it
    const userRef = doc(firestore, "users", `${uid}`);
    const userSnap = await getDoc(userRef);
    //alert(JSON.stringify(userSnap.exists()));


    if (!userSnap.exists()) {
    const { displayName, email } = userAuth;
      try{
        alert(additionalData)
        await setDoc(doc(firestore, "users", `${uid}` ),  {          
          displayName,
          email,
          createdDate: Timestamp.now(),
          ...additionalData})
          alert("Data added to database.")
      }
      catch(err){
        alert(err)
      }
    }
    

    return userRef;
  }
