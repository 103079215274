import React from 'react'
import styled from 'styled-components'
import CustomText from './custom_components/CustomText'

const Card = styled.div`
    background-color: ${props => props.theme.colors.white};
    width:300px;
    height:300px;
    display:flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top:-5px;
    padding-bottom:25px;

    @media (max-width: 456px){
        width:275px;
    }
`
const CardTitleWrapper = styled.div`
    align-content:center;
`

// const Button = styled.p`
//     padding:5px 15px;
//     color: ${props => props.theme.colors.white};
//     background-color: ${props => props.theme.colors.background}; ;
// `

const Image = styled.img`
    height:200px;
    width:300px;
    object-fit: cover;
    @media (max-width: 456px){
        width:275px;
    }   
`

const ShadowCard = styled.div`
    box-shadow:  5px 5px 10px ${props => props.theme.colors.backgroundTransparent};;
    transition: 0.25s ease-in-out;
    &:hover{
        transform:scale(107%);
        
    }
`


export default function StoreCard(props) {
    const {storeName, storeType, address, addressHref, telefon,email, openingHours, img} = props
    return (
        <ShadowCard>
        <Image  src={img}/>
        <Card>
            <CardTitleWrapper>
            <CustomText color="primaryMain" bold><br/>{storeName}</CustomText>
            <CustomText  color="primaryMain">{storeType}</CustomText>
            </CardTitleWrapper>
            <CustomText color="background"><a href={addressHref}>{address}</a></CustomText>
            <div>
            { email !== undefined &&  <CustomText ><a href={"mailto:"+email}>{email}</a></CustomText>}
            { telefon !== undefined && <CustomText color="background">Tel.:{telefon}</CustomText>}
            </div>
            <div>
            { openingHours !== undefined && <CustomText color="background" bold> Nyitvatartás</CustomText>}
            {   openingHours?.map((hour) =>
                <CustomText color="background">{hour}</CustomText>
                )}
            </div>
        </Card>
        </ShadowCard>
    )
}
