 const theme = {
    colors:{
        primaryMain:"#CEA669",
        primaryMainActive:"#927241",
        primarySub:"#624663",
        primarySubActive:"#493249",
        background:"#282826",
        backgroundTransparent:"rgba(40, 40, 38,0.85)",
        backgroundActive:"#121212",
        gray:"#f2f2f2",
        white:"#FFFFFF",
        googleRed:"#DB4437"
    },

    fontSize:{
        xs:"12px",
        sm:"14px",
        md:"18px",
        lg:"24px",
        xl:"36px",
        title:"48px",
    }
 }

 export default theme