import React from 'react'
import styled from 'styled-components'
import { CustomSection, SectionTitle, GridWrapper, BackgroundSvg, NormalWrapper, CustomButton, Spacer } from '../components/custom_components/SharedComponents'
import CustomText from '../components/custom_components/CustomText'
import GalleryImage from '../components/custom_components/GalleryImage'
import BlackLogo from '../assets/imgs/black-logo.svg'

import img1 from '../assets/imgs/wedding/wedding1.png'
import img2 from '../assets/imgs/wedding/wedding2.png'
import img3 from '../assets/imgs/wedding/wedding3.png'
import img4 from '../assets/imgs/wedding/wedding4.png'
import img5 from '../assets/imgs/wedding/wedding5.png'
import img6 from '../assets/imgs/wedding/wedding6.png'
import img7 from '../assets/imgs/wedding/wedding7.png'
import img8 from '../assets/imgs/wedding/wedding8.png'
import img9 from '../assets/imgs/wedding/wedding9.png'
import img10 from '../assets/imgs/wedding/wedding10.png'
import img11 from '../assets/imgs/wedding/wedding11.png'
import img12 from '../assets/imgs/wedding/wedding12.png'




export default function DecorationScreen(props) {
    const {decorRef} = props
    return (
        <>
            {/* <CustomSection>
            <BackgroundSvg src={BlackLogo}/>

                <NormalWrapper>
                <CustomText>Hosszú évek óta dolgozunk azért hogy varázslatossá és felejthetetlenné varázsoljuk azokat a pillanatokat amelyik igazán fontosak önnek. Szakembereink és kreatív csapatunk mindent megtesz hogy a helyszín pontosan olyan legyen mint amilyennek azt megálmodták.
                    Vállaljuk esküvők, évfordulók, születésnapok, névnapok és egyéb magán és nyílvános esemélyek dekorációját.
                </CustomText>
                <CustomButton 
                    color="white"
                    backgroundColor="primarySub"
                    backgroundColorHover="primarySubActive"
                >Kapcsolat</CustomButton>
                </NormalWrapper> 
            </CustomSection> */}
            <Spacer ref={decorRef}/>
            <CustomSection  >
                <SectionTitle>Rendezvény Dekoráció</SectionTitle>
                <BackgroundSvg src={BlackLogo}/>
                <GridWrapper gridGap="auto">
                    <GalleryImage src={img1} alt="Gyönyörű menyasszonyi csokor" title="Menyasszonyi csokor"/>
                    <GalleryImage src={img2} alt="Gyönyörű menyasszonyi csokor" title="Menyasszonyi csokor"/>
                    <GalleryImage src={img3} alt="Kar dísz" title="Kar dísz"/>
                    <GalleryImage src={img4} alt="Kültéri dekoráció" title="Kültéri dekor"/>
                    <GalleryImage src={img5} alt="Gyönyörű menyasszonyi csokor" title="Menyasszonyi csokor"/>
                    <GalleryImage src={img8} alt="Koszorúslány csokrok" title="Koszorúslány csokrok"/>
                </GridWrapper>
            </CustomSection>
            <CustomSection>
                <SectionTitle>Esküvő Dekoráció</SectionTitle>
                <GridWrapper gridGap="auto">
                    <GalleryImage src={img6} alt="Asztaldísz" title="Asztaldísz"/>
                    <GalleryImage src={img9} alt="Asztaldísz" title="Asztaldísz"/>
                    <GalleryImage src={img11} alt="Csokor" title="Csokor"/>
                    <GalleryImage src={img10} alt="Terem dekoráció" title="Terem dekoráció"/>
                    <GalleryImage src={img12} alt="Terem dekoráció" title="Terem dekoráció"/>
                    <GalleryImage src={img7} alt="Terem dekoráció" title="Terem dekoráció"/>
                </GridWrapper>
            </CustomSection>
        </>
    )
}
