import React,{useRef} from 'react'
import TopBar from './Navigation/TopBar'
import HomeScreen from '../screens/HomeScreen'
import Footer from './Footer'
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom'
import NotFoundScreen from '../screens/NotFoundScreen'
import ScrollToTop from './Navigation/ScrollToTop'
import LoginScreen from '../screens/LoginScreen'
import { auth } from '../firebase/utils'
import SignUp from './SignIn/SignUp'
import RegistrationScreen from '../screens/RegistrationScreen'

export default function ScreenController(props) {
    const { currentUser } = props
    const aboutUsRef = useRef(null); 
    const decorRef = useRef(null); 
    const storesRef = useRef(null); 
    
    return (
        <Router>
            <ScrollToTop/>
            <TopBar currentUser={currentUser} aboutUsRef={aboutUsRef} decorRef={decorRef} storesRef={storesRef} />
            <Routes>
                <Route path="/" element={<HomeScreen aboutUsRef={aboutUsRef} decorRef={decorRef} storesRef={storesRef}/>}/>
                {/* <Route path="/uzletek" element={<StoresScreen/>}/>
                <Route path="/dekoracio" element={<DecorationScreen/>}/>
                <Route path="/kertepites" element={<GardeningScreen/>}/>
                <Route path="/kapcsolat" element={<ContactScreen/>}/> */}
                <Route path="/bejelentkezes" element={currentUser ?<Navigate to="/"/> :<LoginScreen/>}/>
                <Route path="/regisztracio" element={currentUser ?<Navigate to="/"/> : <RegistrationScreen/>}/>
                <Route path='*' element={<NotFoundScreen/>} />
            </Routes>
            <Footer/>
        </Router>
    )
}
