import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'

const MenuContainer = styled.ul`
    display:flex;
    position:sticky;
    top:48px;
    flex-direction:column;
    width:100%;
    height:95vh;
    background-color: ${props => props.theme.colors.background};
    justify-content:flex-start;
    align-items:center;
    list-style: none;
    padding:0;
    margin:0;
    transition: transform 0.3s ease-in;
    transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(-100%)'};
    @media (min-width:768px)
    {
        display:none;
    }
`

const MenuItem = styled(Link)`
    text-align:center;
    text-decoration: none;
    width: 100%;
    background-color: ${props => props.theme.colors.background};
    text-transform: uppercase;
    color: ${props => props.theme.colors.primaryMain};
    padding:10px 0px;
    margin:0;
    &:active
    {
        background-color:${props => props.theme.colors.backgroundActive};
        font-weight: bold;
    }


`

export default function MobileNavMenu({open, setOpen}) {
    return (
        <>
       { open ===  true &&
        <MenuContainer open={open}>
            {MenuItems.map((option) =>                 
            <MenuItem key={option.title} to={option.path} onClick={() => {setOpen(!open)}}>{option.title}</MenuItem>
        )}
        </MenuContainer>
        }
        </>
    )
}


const MenuItems=[
    {
        title:"Főoldal",
        path:"/"
    },
    {
        title:"Üzletek",
        path:"/uzletek"
    },
    {
        title:"Dekoráció",
        path:"/dekoracio"
    },
    {
        title:"Kertépítés",
        path:"/kertepites"
    },
    {
        title:"Kapcsolat",
        path:"/kapcsolat"
    },

]

