import React from 'react'
import { BackgroundSvg, CustomSection,  } from '../components/custom_components/SharedComponents'
import BlackLogo from '../assets/imgs/black-logo.svg'
import SignUp from '../components/SignIn/SignUp'


function RegistrationScreen() {
    return (
        <div>
            <BackgroundSvg src={BlackLogo}/>
            <CustomSection padding="150px 0 25px">
                <SignUp/>
            </CustomSection>
        </div>
    )
}

export default RegistrationScreen