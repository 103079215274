import styled from "styled-components"
import { Link  } from "react-router-dom"

export const Spacer = styled.div`
        margin-top:64px;

    @media (min-width:768px)
    {
        margin-top:64px;
    }
`

export const CustomSection = styled.section`
    width: 100%;
    height: auto;
    min-height: ${props=> props.height ||"45vh" };
    align-items: center;
    background-color:  ${props => props.backgroundColor ? props.theme.colors[props.backgroundColor] || props.backgroundColor : "none"};
    background-image: url(${props => props.backgroundImage || "none"});
    background-position: ${props => props.backgroundPosition};
    background-size: ${props => props.backgroundSize || "auto"} ;
    background-repeat: no-repeat;
    display:flex;
    flex-direction:column;
    justify-content:center;
    padding:${props => props.padding || "48px 0"};
`
export const GridWrapper = styled.div`
    margin: ${props => props.margin || "0 auto"};
    display:grid;
    width:1000px;
    grid-gap: ${props =>props.gridGap || "16px"};
    justify-items: center;
    align-items:top;
    max-width:275px;
    grid-row-gap: 30px;

    @media (min-width: 456px){
    max-width:450px;

    }
    @media (min-width: 768px)
    {
        max-width:750px;
        grid-template-columns: ${props=> props.template || "repeat(2, 1fr)"};
        grid-gap: ${props =>props.gridGap || "48px"};
        grid-column-gap: 48px;

    }
    @media (min-width: 1001px){
     max-width:1000px;
        grid-template-columns: ${props=> props.template || "repeat(3,1fr)"};
        grid-gap: ${props =>props.gridGap || "48px"}
    }

`

export const HalfGridWrapper = styled.div`
    max-width:85%;
    width:100%;
    height:100%;
    justify-items: center;
    align-items:center;
    display:flex;
    flex-direction: vertical;
    margin:auto;

    @media (min-width: 456px){
    max-width:450px;
    }
`

export const CustomGridWrapper = styled.div`
    padding-top:48px;
    width:${props=>props.width || "100%"};
    display:grid;
    padding:${props=>props.padding};
    grid-gap: ${props =>props.gridGap || "48px"};

    @media (min-width: 1001px){
        padding-top:0px;
        grid-template-columns: ${props=> props.template || "repeat(3,1fr)"};
        grid-gap: ${props =>props.gridGap || "0px"}
    }
    
`

export const NormalWrapper = styled.div`
    margin: 0 auto;
    justify-items: center;
    align-content:center;
    align-items:center;
    min-width:275px;
    max-width:85%;
    padding:${props => props.padding || "0"};
    @media (min-width: 456px){
     max-width:450px;
     }

    @media (min-width: 1000px){
     max-width:1000px;
     width:1000px;
     }
`

export const SectionTitle = styled.h2`
    margin: "24px auto";
    margin-top:${props => props.marginTop} ;
    padding: 0;
    text-transform: uppercase;
    font-size: ${props=> props.theme.fontSize.md};
    color:  ${props => props.color ? props.theme.colors[props.color] || props.color : props.theme.colors.white };
    font-weight:normal;
    letter-spacing:2.5px;
    text-shadow: 2px 2px 3px ${props => props.theme.colors.backgroundTransparent};;
;
    @media (min-width: 768px)
    {    
        font-size: ${props=> props.theme.fontSize.lg};
        margin-bottom:28px;
    }

` 

export const BackgroundSvg = styled.img`
    width:500px;
    height:500px;
    position: fixed;
    right:-160px;
    bottom:-65px;
    overflow:hidden;
    z-index:-1;
    opacity:20%;

    @media (min-width: 768px)
    {
        width:800px;
        height:800px;
        right:-180px;
        bottom:-180px;
    }
`
export const SimpleLink = styled(Link)`
    text-decoration:none;
`

export const CustomButton = styled.button`
  text-transform: uppercase;
      padding: 12px 48px;
      border-radius: 20px;
      margin: ${props=> props.margin || "25px 0"};
      font-size: 14px;
      letter-spacing: 1.5px;
      border-style:none;
      background-color: ${props => props.backgroundColor ? props.theme.colors[props.backgroundColor] || props.backgroundColor : props.theme.colors.background};
      color: ${props => props.color ? props.theme.colors[props.color] || props.color : props.theme.colors.white};
      &:hover
      {
          background-color:${props => props.backgroundColorHover ? props.theme.colors[props.backgroundColorHover] || props.backgroundColorHover : props.theme.colors.backgroundActive};
      }
`

export const Line = styled.div`
    height: 2px;
    width: ${props => props.width || "90%"};
    margin: ${props => props.margin || "16px auto"};
    background-color: ${props => props.backgroundColor ? props.theme.colors[props.backgroundColor] || props.backgroundColor : props.theme.colors.primaryMain};
`
export const FlexBox = styled.div`
    display:flex;
    width:100%;
    height:100%;
    flex-direction:${props=>props.direction || "vertical"};
`

export const CustomInput = styled.input`
    width:${props =>props.width || "200px"};
    background:${props => props.theme.colors.backgroundTransparent};
    border: 5px 5px;
    border-style: solid;
    border-color:${props => props.theme.colors.primaryMain};
    padding: 5px 10px;
    color:white;
    letter-spacing: 0.75px;
    font-size: 14px;
    
    &:active{
        border-color:${props => props.theme.colors.primaryMainActive}
    }
    
    @media (min-width: 768px)
    {        
        min-width:400px;
    }  
`